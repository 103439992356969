<template>
  <div>
    <b-alert
      :show="form.errors && form.errors.length > 0"
      variant="light"
      class="alert red lighten-4"
      ref="alert"
    >
      <div class="alert-icon">
        <i class="flaticon-warning kt-font-danger"></i>
      </div>
      <div class="alert-text">
        <div v-for="err in form.errors" :key="err">
          {{ err }}
        </div>
      </div>
    </b-alert>
    <div class="row">
      <div class="col-md-12">
        <KTPortlet v-bind:title="title">
          <template v-slot:body>
            <v-form
              ref="form"
              @submit.prevent="submitForm"
              v-model="form.valid"
              lazy-validation
            >
              <v-row>
                <v-col cols="12" md="12">
                  <v-text-field
                    v-model="form.title"
                    :disabled="form.loading"
                    :counter="200"
                    :label="$t('MANAGE_NOTIFICATION.ADD_EDIT.TITLE')"
                    :rules="form.titleRules"
                    required
                  ></v-text-field>
                </v-col>

                <v-col cols="12" md="12">
                  <v-textarea
                    v-model="form.body"
                    :disabled="form.loading"
                    :counter="2000"
                    :label="$t('MANAGE_NOTIFICATION.ADD_EDIT.BODY')"
                    :rules="form.bodyRules"
                    required
                  ></v-textarea>
                </v-col>

                <v-col cols="12" md="12">
                  <v-text-field
                    v-model="form.titleEN"
                    :disabled="form.loading"
                    :counter="200"
                    :label="$t('MANAGE_NOTIFICATION.ADD_EDIT.TITLE_EN')"
                    :rules="form.titleENRules"
                    required
                  ></v-text-field>
                </v-col>

                <v-col cols="12" md="12">
                  <v-textarea
                    v-model="form.bodyEN"
                    :disabled="form.loading"
                    :counter="2000"
                    :label="$t('MANAGE_NOTIFICATION.ADD_EDIT.BODY_EN')"
                    :rules="form.bodyENRules"
                    required
                  ></v-textarea>
                </v-col>

                <v-col cols="12" md="12">
                  <v-text-field
                    v-model="form.url"
                    :disabled="form.loading"
                    :counter="1000"
                    :label="$t('MANAGE_NOTIFICATION.ADD_EDIT.URL')"
                    :rules="form.urlRules"
                    required
                  ></v-text-field>
                </v-col>

                <v-col cols="12" md="6">
                  <v-autocomplete
                    v-model="form.sendGroupType"
                    :disabled="form.loading"
                    :items="form.sendGroupTypeItems"
                    :loading="form.typeLoading"
                    :search-input.sync="form.typeSearch"
                    :rules="form.sendGroupTypeRules"
                    hide-no-data
                    hide-selected
                    item-text="text"
                    item-value="value"
                    :label="$t('MANAGE_NOTIFICATION.ADD_EDIT.SEND_STATUS_GROUP')"
                    return-object
                    clearable
                  ></v-autocomplete>
                </v-col>

                <v-col cols="12" md="3">
                  <v-menu
                    ref="useDateMenu"
                    v-model="form.useDateMenu"
                    :disabled="form.loading"
                    :close-on-content-click="false"
                    :nudge-right="5"
                    transition="scale-transition"
                    offset-y
                    min-width="290px"
                  >
                    <template v-slot:activator="{ on }">
                      <v-text-field
                        v-model="computeduseDateFormatted"
                        :disabled="form.loading"
                        :label="$t('MANAGE_NOTIFICATION.ADD_EDIT.USE_DATE')"
                        :rules="form.useDateRules"
                        hint="DD/MM/YYYY format"
                        persistent-hint
                        readonly
                        v-on="on"
                      ></v-text-field>
                    </template>
                    <v-date-picker
                      v-model="form.useDate"
                      scrollable
                      :disabled="form.loading"
                      :min="form.minDate"
                    >
                      <v-spacer></v-spacer>
                      <v-btn
                        text
                        color="primary"
                        @click="form.useDateMenu = false"
                        >Cancel</v-btn
                      >
                      <v-btn
                        text
                        color="primary"
                        @click="$refs.useDateMenu.save(form.useDate)"
                        >OK</v-btn
                      >
                    </v-date-picker>
                  </v-menu>
                </v-col>

                <v-col cols="12" md="3">
                  <v-menu
                    ref="useTimeMenu"
                    v-model="form.useTimeMenu"
                    :disabled="form.loading"
                    :close-on-content-click="false"
                    :nudge-right="5"
                    transition="scale-transition"
                    offset-y
                    min-width="290px"
                  >
                    <template v-slot:activator="{ on }">
                      <v-text-field
                        v-model="form.useTime"
                        :disabled="form.loading"
                        :label="$t('MANAGE_NOTIFICATION.ADD_EDIT.USE_TIME')"
                        :rules="form.useTimeRules"
                        hint="HH:MM format"
                        persistent-hint
                        readonly
                        v-on="on"
                      ></v-text-field>
                    </template>
                    <v-time-picker
                      v-model="form.useTime"
                      format="24hr"
                      scrollable
                      :disabled="form.loading"
                    >
                      <v-spacer></v-spacer>
                      <v-btn
                        text
                        color="primary"
                        @click="form.useTimeMenu = false"
                        >Cancel</v-btn
                      >
                      <v-btn
                        text
                        color="primary"
                        @click="$refs.useTimeMenu.save(form.useTime)"
                        >OK</v-btn
                      >
                    </v-time-picker>
                  </v-menu>
                </v-col>

                <v-col cols="12" md="12">
                  <v-checkbox
                    v-model="form.inActiveStatus"
                    :disabled="form.loading"
                    :label="$t('MANAGE_NOTIFICATION.ADD_EDIT.IN_ACTIVE_STATUS')"
                    required
                  ></v-checkbox>
                </v-col>
              </v-row>

              <v-row>
                <div class="col-12">
                  <v-btn
                    :disabled="!form.valid || form.loading"
                    color="success"
                    class="mr-4"
                    tile
                    type="submit"
                  >
                    <v-icon left>la-save</v-icon>
                    {{ $t("SHARED.SAVE_BUTTON") }}
                  </v-btn>
                  <v-btn
                    :disabled="form.loading"
                    color="default"
                    class="mr-4"
                    type="reset"
                    tile
                    @click.prevent="resetForm"
                  >
                    <v-icon left>mdi-eraser</v-icon>
                    {{ $t("SHARED.RESET_BUTTON") }}
                  </v-btn>
                </div>
              </v-row>

              <v-dialog v-model="form.dialog" persistent max-width="300">
                <v-card>
                  <v-card-title class="headline">
                    {{
                      $t("MANAGE_NOTIFICATION.ADD_EDIT.SUCCESS_DIALOG_HEADER")
                    }}</v-card-title
                  >
                  <v-card-text>
                    {{ $t("MANAGE_NOTIFICATION.ADD_EDIT.ADD_SUCCESS_DIALOG_TEXT") }}
                  </v-card-text>
                  <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="green darken-1" text @click="closeDialog">{{
                      $t("SHARED.CLOSE_BUTTON")
                    }}</v-btn>
                  </v-card-actions>
                </v-card>
              </v-dialog>

              <v-dialog
                v-model="form.loading"
                persistent
                hide-overlay
                width="300"
              >
                <v-card>
                  <v-card-title class="headline">
                    {{ $t("SHARED.PLEASE_WAIT") }}</v-card-title
                  >
                  <v-card-text>
                    <p>
                      {{ $t("SHARED.PROCESSING") }}
                    </p>
                    <v-progress-linear
                      indeterminate
                      color="amber lighten-3"
                      class="mb-3"
                    ></v-progress-linear>
                  </v-card-text>
                </v-card>
              </v-dialog>
            </v-form>
          </template>
        </KTPortlet>
      </div>
    </div>
  </div>
</template>

<script>
import ApiService from "@/common/api.service";
import { SET_BREADCRUMB } from "@/store/breadcrumbs.module";
import KTPortlet from "@/views/partials/content/Portlet.vue";

export default {
  components: {
    KTPortlet
  },
  data() {
    return {
      form: {
        valid: true,
        dialog: false,
        loading: false,
        errors: [],

        title: null,
        titleEN: null,
        body: null,
        bodyEN: null,
        url: "",
        sendGroupType: null,
        inActiveStatus: true,

        //TIME
        useDateMenu: false,
        useDate: null,
        useDateFormatted: this.formatDate(
          new Date().toISOString().substr(0, 10)
        ),
        useTime: "00:00",
        useTimeMenu: false,
        minDate: new Date().toISOString().substr(0, 10),
        //TIME
  
        titleRules: [
          (v) => 
          !!v || this.$t("MANAGE_NOTIFICATION.ADD_EDIT.TITLE_VALIDATION"),
          (v) =>
            (v && v.length <= 200) ||
            this.$t("MANAGE_NOTIFICATION.ADD_EDIT.TITLE_COUNTER"),
        ],
        titleENRules: [
          (v) => 
          !!v || this.$t("MANAGE_NOTIFICATION.ADD_EDIT.TITLE_EN_VALIDATION"),
          (v) =>
            (v && v.length <= 200) ||
            this.$t("MANAGE_NOTIFICATION.ADD_EDIT.TITLE_EN_COUNTER"),
        ],
        bodyRules: [
          (v) => 
          !!v || this.$t("MANAGE_NOTIFICATION.ADD_EDIT.BODY_VALIDATION"),
          (v) =>
            (v && v.length <= 2000) ||
            this.$t("MANAGE_NOTIFICATION.ADD_EDIT.BODY_COUNTER"),
        ],
        bodyENRules: [
          (v) => 
          !!v || this.$t("MANAGE_NOTIFICATION.ADD_EDIT.BODY_EN_VALIDATION"),
          (v) =>
            (v && v.length <= 2000) ||
            this.$t("MANAGE_NOTIFICATION.ADD_EDIT.BODY_EN_COUNTER"),
        ],
        urlRules: [
          (v) =>
            (v.length <= 1000) || this.$t("MANAGE_NOTIFICATION.ADD_EDIT.URL_COUNTER"),
        ],
        useDateRules: [
          (v) =>
            !!v || this.$t("MANAGE_NOTIFICATION.ADD_EDIT.USE_DATE_VALIDATION"),
        ],
        endDateRules: [
          (v) => !!v || this.$t("MANAGE_NOTIFICATION.ADD_EDIT.USE_TIME_VALIDATION"),
        ],
        sendGroupTypeRules: [
          (v) => !!v || this.$t("MANAGE_NOTIFICATION.ADD_EDIT.SEND_STATUS_GROUP_VALIDATION"),
        ],

        typeSearch: "",
        sendGroupTypeItems: [],
      },
    };
  },
  computed: {
    title() {
      return this.$t("MENU.MANAGE_NOTIFICATION.ADD");
    },
    computeduseDateFormatted() {
      return this.formatDate(this.form.useDate);
    }
  },
  watch: {
    "form.typeSearch": {
      handler() {
        this.getTypeFromApi().then((data) => {
          this.form.sendGroupTypeItems = data;
        });
      },
    },
    "form.useDate": {
      handler() {
        this.form.useDateFormatted = this.formatDate(this.form.useDate);
      },
    }
  },
  methods: {
    formatDate(date) {
      if (!date) return null;

      const [year, month, day] = date.split("-");
      return `${day}/${month}/${year}`;
    },
    submitForm() {
      this.form.errors = [];

      if(this.form.url != ""){
        if (!this.validateUrl(this.form.url)){
          this.form.errors.push("กรุณากรอกข้อมูลในรูปแบบลิงค์");
        }
      }

      if(this.$refs.form.validate() && this.form.errors.length == 0)
      {
        this.postDataToApi();
      }
    },
    resetForm() {
      this.form.descriptionTH = "";
      this.form.descriptionEN = "";
      this.$refs.form.reset();
    },
    postDataToApi() {
      // return
      this.form.loading = true;
      this.form.errors = [];

      ApiService.setHeader();
      ApiService.post("/Api/ManageNotification/Add", {
        Title: this.form.title,
        TitleEN: this.form.titleEN,
        Body: this.form.body,
        BodyEN: this.form.bodyEN,
        Url: this.form.url,
        SendGroupCode: this.form.sendGroupType.value,
        UseDateString: this.form.useDateFormatted,
        UseTimeString: this.form.useTime,
        InActiveStatus: !this.form.inActiveStatus
      })
        .then(({ data }) => {
          if (data.status) {
            // close dialog
            this.form.dialog = true;
          } else {
            this.form.dialog = false;
            this.form.loading = false;
            this.form.errors.push(!!data.message || "Unknow error occurs");
            this.$vuetify.goTo(this.$refs.alert, {
              duration: 1000,
              easing: "easeInOutCubic",
              offset: -20,
            });
          }
        })
        .catch(({ response }) => {
          if (response.data) {
            this.form.errors.push(response.data.message);
          } else {
            this.form.errors.push("Unknow error occurs");
          }
          this.$vuetify.goTo(this.$refs.alert, {
            duration: 1000,
            easing: "easeInOutCubic",
            offset: -20,
          });
          this.form.dialog = false;
          this.form.loading = false;
        });
    },
    closeDialog() {
      // not close but redirect to search page
      this.$router.push({ name: "SearchManageNotification" });
    },
    getTypeFromApi() {
      return new Promise((resolve) => {
        ApiService.setHeader();
        ApiService.post("/Api/ManageNotification/Select2ManageNotificationType", {
          query: null,
        })
          .then(({ data }) => {
            resolve(data);
          })
          .finally(() => {
            this.form.typeLoading = false;
          });
      });
    },
    validateUrl(value) {
      var url_pattern = /((http|https):\/\/)(\S+)(:[0-9]+)?(\/|\/([\w#!:.?+=&%@!\-\\/]))?/i;
      return value.match(url_pattern);
    },
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      {
        title: this.$t("MENU.MANAGE_NOTIFICATION.SECTION"),
        route: "/ManageNotification",
      },
      { title: this.$t("MENU.MANAGE_NOTIFICATION.ADD") },
    ]);
  },
};
</script>

<style lang="scss" scoped></style>
